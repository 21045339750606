import React from 'react';
import styled from 'styled-components';
import {
  lineheight,
  fontSize,
  fontWeight,
  m,
  maxwidth,
  mt,
  mb,
  mr,
  ml,
  display,
  textalign,
  wordbreak,
  fontFamily,
  texttransform,
  minwidth,
  textoverflow,
  overflow,
  p,
  bgcolor,
  borderradius
} from '../../properties';
import { colors } from '../../constant';

const Text = styled(({ children, className, ...props }) => (
  <div className={className} {...props}>
    {children}
  </div>
))`
  color: ${props => (props.color ? props.color : colors.bodyColor)};
  width: ${props => (props.isMobile ? '90%' : 'inherit')};
  word-wrap: break-word;
  ${fontSize}
  ${fontWeight} 
  ${fontFamily}
  ${lineheight}
  ${mt} 
  ${mb} 
  ${mr}
  ${ml}
  ${m}
  ${p}
  ${maxwidth}
  ${display} 
  ${textalign} 
  ${wordbreak} 
  ${texttransform}
  ${minwidth}
  ${textoverflow} 
  ${overflow} 
  ${bgcolor} 
  ${borderradius}
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  ${({ center }) =>
    center && 'display: flex; justify-content: center; align-items: center;'}
  ${({ centeritems }) => centeritems && 'display: flex; align-items: center;'}
  ${({ left }) => left && 'display: flex; justify-content: flex-start'}
  ${({ background }) => background && `background: ${background};`};
`;

export default Text;
