import { requestPublicApi } from '../../../services/HttpRequester';

import { colors } from '../../constant';

export const languages = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'it',
    label: 'Italiano'
  },
  {
    value: 'de',
    label: 'Deutsch'
  },
  {
    value: 'pt',
    label: 'Português(BR)'
  }
];

export const colourStyles = (theme, isMobile) => {
  return {
    control: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      width: isMobile ? 145 : 200,
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      color: 'navActiveColor',
      cursor: 'pointer'
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? theme.themes.colorPrimary : 'white',
        color: isSelected ? 'white' : 'black',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: isSelected
            ? theme.themes.colorPrimary
            : colors.lightGray,
          color: isSelected ? 'white' : colors.bodyColor
        }
      };
    },
    menu: styles => ({
      ...styles,
      minWidth: isMobile ? 175 : 230,
      left: -29
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: theme.themes.colorPrimary
    }),
    indicatorSeparator: (styles, { data }) => ({ ...styles, display: 'none' })
  };
};

export function saveLanguage(language, userId) {
  return requestPublicApi({
    method: 'PUT',
    url: `/language/${userId}?code=${language}`,
    headers: {
      accept: '*/*'
    }
  });
}
