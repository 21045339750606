import styled from 'styled-components';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import i18n from '../../../i18n';

export const Table = styled(DataGrid).attrs(props => ({
  sx: {
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '4px'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent'
    },
    '& .MuiDataGrid-virtualScroller:hover::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '& .MuiDataGrid-virtualScroller:hover::-webkit-scrollbar-thumb': {
      background: '#999'
    },
    '& .MuiDataGrid-actionsCell': {
      button: {
        display: 'none'
      },
      "[aria-label='more']": {
        display: 'inline-flex'
      }
    },
    border: 0,
    '& .MuiDataGrid-cell': {
      borderColor: '#F5F6F8',
      color: '#525E6B',
      cursor: 'pointer',

      '&:focus-within': {
        outline: 'none'
      }
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiCheckbox-root': {
      width: 13,
      height: 13,
      padding: 0,
      border: '1px solid #F1F1F1',
      borderRadius: '2px !important',
      backgroundColor: 'white'
    },
    '& .MuiCheckbox-root svg': {
      width: 7,
      height: 7,
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '1px'
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none'
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '1px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 13,
      height: 13
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 13,
      height: 13,
      backgroundColor: 'inherit',
      transform: 'none',
      top: '39%',
      border: 0
    },

    '&.notFirstLastPage': {
      '.MuiPaginationItem-firstLast': {
        display: 'none'
      },

      '.MuiPagination-ul': {
        li: {
          border: '1px solid #F1F1F1',
          borderRight: 0,

          '.MuiPaginationItem-previousNext': {
            borderRight: 1,
            borderColor: '#F1F1F1',
            opacity: 1
          }
        }
      }
    }
  },

  componentsProps: {
    pagination: {
      labelRowsPerPage: i18n.t('rowsPerPage'),
      SelectProps: {
        MenuProps: {
          anchorOrigin: {
            vertical: 'top'
          },
          transformOrigin: {
            vertical: 'center'
          },
          PaperProps: {
            sx: {
              marginTop: '10px !important'
            }
          }
        }
      },
      labelDisplayedRows: function defaultLabelDisplayedRows({
        from,
        to,
        count
      }) {
        return `${from}–${to} ${i18n.t('of')} ${
          count !== -1 ? count : `more than ${to}`
        }`;
      }
    },
    basePopper: {
      sx: {
        '& .MuiPaper-root': {
          boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)'
        },
        '& .MuiDataGrid-menuList': {
          paddingRight: '8px',
          paddingLeft: '8px',
          round: '0.4',
          color: '#6C757D'
        },
        '& .MuiMenuItem-root': {
          fontSize: '14px',
          padding: '10px',
          borderRadius: '4px'
        },
        '& .MuiFormControl-root.MuiDataGrid-filterFormColumnInput, & .MuiFormControl-root.MuiDataGrid-filterFormOperatorInput': {
          width: 'max-content'
        },
        ...props.sx
      }
    }
  }
}))`
  .MuiDataGrid-columnHeaders {
    background-color: #f5f6f8;
    border: 0;
    padding: 0 10px;
  }
  .MuiDataGrid-columnHeaders .actions__header {
    display: none !important;
  }
  .mfa__header {
    width: 100% !important;
    max-width: 100% !important;
  }

  .member__header .MuiDataGrid-iconButtonContainer {
    margin-left: 10px;
  }

  .MuiDataGrid-columnHeader {
    outline: none !important;
  }

  .MuiDataGrid-row {
    padding: 0 10px;
  }

  .MuiDataGrid-footerContainer {
    border: 0;
  }

  .MuiDataGrid-selectedRowCount {
    opacity: 0;
  }

  .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
    background-color: ${({ theme }) => theme.themes?.colorPrimary};
    border-color: ${({ theme }) => theme.themes?.colorPrimary};
  }
`;

export const ActionMenuItem = styled(GridActionsCellItem)`
  min-width: 130px;
`;
