/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Modal } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { wait } from '@testing-library/user-event/dist/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../../constants/breakpoints';
import { formatDateWithUtc } from '../../../utils/format.dates.utils';
import { showToast, showUploadToast } from '../../../hooks/show-toast';
import hasPermission from '../../../security/Permission';
import useDebounce from '../../../hooks/use-debounce';
import { allColumns } from '../../../utils/columns';
import { Table } from '../../common/table/Table';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import Icon from '../../common/icon/Icon';
import Button from '../../common/button/Button';
import DeleteModal from '../../common/modal/DeleteModal';
import { colors } from '../../constant';
import { LoaderWrap } from '../../common/loader/Loader';
import { ButtonToggle } from '../../common/button/ButtonToggle';
import { renderCellExpand } from '../../common/table/TableCellExpand';
import { CustomPagination } from '../../common/pagination/Pagination';

import { localizedTextsMap } from '../helpers';
import { UploadFilesModal } from '../create/UploadFilesModal';
import { CreateFolderModal } from '../create/CreateFolderModal';
import { EmptyObjectsView } from './EmptyObjectsView';
import {
  createFolder,
  deleteBucketObjects,
  getAllBucketObjects,
  toggleShowVersions
} from '../endpoints';
import BucketObjectDetails from './BucketObjectDetails';

export function BucketObjects() {
  const { name, '*': directory } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathNames = location.pathname.split('/').filter(x => x);
  const breadCrumbsNames = pathNames.filter(item => item !== 'objects');
  const pageSize = 20;
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  const canReadObjectsPerm = hasPermission('oem_objects', 'read_all');
  const canCreateFilePerm = hasPermission('oem_objects', 'create_file');
  const canCreateFolderPerm = hasPermission('oem_objects', 'create_folder');
  const canReadFolderPerm = hasPermission('oem_objects', 'read_folder');
  const canDeleteFilePerm = hasPermission('oem_objects', 'delete_file');
  const canDeleteFolderPerm = hasPermission('oem_objects', 'delete_folder');
  const [toggleRefetch, setToggleRefetch] = useState(true);
  const [response, setResponse] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteText, setShowDeleteText] = useState(false);
  const [showDeleteObjects, setShowDeleteObjects] = useState(false);
  const [showObjectDetails, setShowObjectDetails] = useState(false);
  const [objectName, setObjectName] = useState('');
  const [versionId, setVersionId] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFocus, setSearchFocus] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSearchingTable, setIsSearchingTable] = useState(false);
  const [totalRows, setTotalRows] = useState(pageSize);
  const [isVersioningToggled, setIsVersioningToggled] = useState(false);
  const [viewingAllVersions, setViewingAllVersions] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [paginationList, setPaginationList] = useState([
    {
      page: 1,
      continuationToken: ''
    }
  ]);
  const { uploadInProgress } = useStoreState(actions => actions.fileUpload);
  const { setUploadInProgress } = useStoreActions(
    actions => actions.fileUpload
  );
  const { lastObjectsByPage } = useStoreState(actions => actions.bucketObjects);
  const { setLastObjectsByPage } = useStoreActions(
    actions => actions.bucketObjects
  );
  const { conToken } = useStoreState(actions => actions.bucketDetails);
  const { setConToken } = useStoreActions(actions => actions.bucketDetails);
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const handleIsLockingToggled = () => {
    setDisplayLoader(true);
    toggleShowVersions().then(([res]) => {
      if (res?.success) {
        setSelectedRows([]);
        setShowDeleteText(false);
        fetchBucketObjects(1);
        setIsVersioningToggled(!isVersioningToggled);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
    });
    setSearchFocus(false);
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    fileSize: true,
    lastModified: true
  });

  useEffect(() => {
    setIsVersioningToggled(response?.ShowVersions);
  }, [response]);
  useEffect(() => {}, [currentPage]);
  useEffect(() => {}, [paginationList]);

  const fetchNextPage = page => {
    setResponse(undefined);
    const existingPage = paginationList
      .filter(item => item.page === page)
      .pop();
    if (existingPage) {
      setConToken(existingPage.continuationToken);
    } else {
      setConToken(response.ContinuationToken);
      setPaginationList(prev => [
        ...prev,
        {
          page,
          continuationToken: response.ContinuationToken
        }
      ]);
    }
    fetchBucketObjects(page);
  };

  const renderFileSize = params => {
    return <div>{params.row.fileSize}</div>;
  };

  const versionColumn = {
    field: 'versionId',
    editable: true,
    headerName: t('versionId'),
    renderCell: params => (
      <>
        {params.row?.versionId && params.row?.versionId !== 'null'
          ? params.row?.versionId
          : t('notAvailable')}
      </>
    ),
    flex: 1
  };

  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      renderCell: params =>
        renderCellExpand({
          value: params.row.name.includes('/')
            ? params.row.name.substring(0, params.row.name.length - 1)
            : params.row.name,
          width: params,
          fontWeight: '500',
          iconName: params.row.name.endsWith('/')
            ? 'folder'
            : params.row?.isLatest === false
            ? 'versionedFile'
            : 'file',
          iconMinWidth: '24px'
        })
    },
    {
      field: 'fileSizeNumber',
      headerName: t('size'),
      flex: 1,
      valueGetter: params => {
        if (params.row?.name.endsWith('/')) {
          return null;
        }
        return params.row?.fileSizeNumber;
      },
      renderCell: renderFileSize
    },
    {
      field: 'lastModified',
      headerName: t('lastModified'),
      flex: 1,
      minWidth: 220,
      type: 'date',
      headerClassName: 'pr-40',
      renderCell: params => {
        if (params.row?.name.endsWith('/')) {
          return '';
        } else {
          return formatDateWithUtc(params.row?.lastModified);
        }
      },
      sortComparator: (v1, v2, param1, param2) =>
        new Date(param1.api.getCellValue(param1.id, 'lastModified')) -
        new Date(param2.api.getCellValue(param2.id, 'lastModified'))
    }
  ]);

  const fetchBucketObjects = (
    page,
    search,
    isSearching = false,
    displayLoader = true
  ) => {
    if (!page) {
      page = paginationList[paginationList.length - 1].page;
    }
    setDisplayLoader(true);

    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
    }

    const memoToken = lastObjectsByPage?.find(item => item.page === page);

    getAllBucketObjects(
      name,
      directory,
      memoToken?.conToken ? memoToken?.conToken : page === 1 ? '' : conToken,
      pageSize,
      search != null ? search : searchValue
    ).then(([res, error, loading, redirectError]) => {
      if (res.success) {
        setResponse(res.data);
        if (res?.data?.showVersions !== undefined) {
          setIsVersioningToggled(res?.data?.showVersions);
        }
        if (res.data.HasMoreElements) {
          setTotalRows(page * pageSize + pageSize);
        } else {
          setTotalRows(page * pageSize);
        }

        if (isSearching) {
          setSearchFocus(true);
          setIsSearchingTable(true);
        }
        setLastObjectsByPage({
          page: page + 1,
          conToken: res.data?.ContinuationToken
        });
        setConToken(res.data?.ContinuationToken);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );

        if (redirectError) {
          wait(500).then(() => {
            navigate(redirectError, { replace: true });
          });
        }
      }
      setDisplayLoader(false);
      setViewingAllVersions(false);
    });
  };

  useEffect(() => {
    if (debouncedSearchTerm?.length > 0) fetchBucketObjects(1, null, true);
  }, [debouncedSearchTerm, toggleRefetch]);

  useEffect(() => {
    if (debouncedSearchTerm?.length === 0) {
      fetchBucketObjects(1);
    }
  }, [location, debouncedSearchTerm]);

  /**
   * Create folder.
   */
  const [showCreateFolder, setShowCreateFolder] = React.useState(false);
  const showCreateFolderModal = React.useCallback(() => {
    setSearchFocus(false);
    setShowCreateFolder(true);
  }, []);
  const handleCreateFolder = props => {
    setDisplayLoader(true);
    createFolder(name, props.name, directory).then(([res]) => {
      setDisplayLoader(false);
      if (res?.success) {
        setShowCreateFolder(false);
        setPaginationList([]);
        fetchBucketObjects(1);
      }
      if (res?.code || res?.Code !== 'ErrCcc0045')
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
    });
  };

  /**
   * Upload Files.
   */
  const [preDeclaredFiles, setPreDeclaredFiles] = React.useState([]);
  const [showUploadFiles, setShowUploadFiles] = React.useState(false);
  const showUploadFilesModal = React.useCallback(files => {
    setSearchFocus(false);
    if (files && files.length > 0) {
      setPreDeclaredFiles(files);
    }
    setShowUploadFiles(true);
  }, []);

  const uploadComplete = props => {
    setPaginationList([]);
    fetchBucketObjects(1);
  };

  const handleClickEvent = (params, event) => {
    setSelectedRows([]);
    setPaginationList([]);
    setShowDeleteText(false);
    setShowDeleteObjects(false);
    setSearchFocus(false);
    setIsSearchingTable(false);
    event.defaultMuiPrevented = true;
    if (params?.row.name.endsWith('/')) {
      if (canReadFolderPerm) {
        setSearchValue('');
        navigate(
          location.pathname
            .concat('/')
            .concat(
              encodeURIComponent(
                params?.row.name.substring(0, params?.row.name.length - 1)
              )
            )
        );
        // navigate(0);
      }
    } else {
      setObjectName(params?.row.name);
      setVersionId(params?.row.versionId);
      setShowObjectDetails(true);
    }
  };

  const handleSelectCheckboxChange = ids => {
    let objectNames = ids.map(id =>
      response?.Content.find(row => row.id === id)
    );
    if (!canDeleteFolderPerm) {
      objectNames = objectNames.filter(item => !item.name.endsWith('/'));
    }
    if (!canDeleteFilePerm) {
      objectNames = objectNames.filter(item => item.name.endsWith('/'));
    }
    setSelectedRows(objectNames);
    setShowDeleteText(objectNames.length > 0);
  };

  /**
   * Delete bucket.
   */
  const onDeleteClick = useCallback(
    name => () => {
      setShowDeleteObjects(true);
    },
    []
  );
  const handleDeleteObjects = () => {
    setDisplayLoader(true);
    setDisplayLoader(true);
    setButtonLoader(true);
    deleteBucketObjects(name, selectedRows, directory).then(([res]) => {
      setDisplayLoader(false);
      setButtonLoader(false);
      setShowDeleteText(false);
      setShowDeleteObjects(false);
      setSearchValue('');
      setIsSearchingTable(false);
      setSelectedRows([]);
      setPaginationList([]);
      fetchBucketObjects(1);
      if (res?.code || res?.Code !== 'ErrCcc0045')
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
    });
  };

  const updateNamePerformed = () => {
    fetchBucketObjects(1, null, false, false);
  };

  const deletePerformed = () => {
    setShowObjectDetails(false);
    fetchBucketObjects(1);
  };

  const closeUploadFilesModal = () => {
    setShowUploadFiles(false);
    setPreDeclaredFiles([]);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
    return () => setConToken('');
  }, []);

  function QuickSearchToolbar() {
    return (
      <SerachWrapper>
        <ContentWrapper>
          <Search
            isMobile={isMobile}
            InputProps={{
              disableUnderline: true,
              autoFocus: searchFocus,
              placeholder: t('searchObjects'),
              endAdornment: (
                <Icon
                  icon="x"
                  width="16px"
                  height="16px"
                  color={colors.gray20}
                  onClick={() => {
                    setSearchValue('');
                    setSearchFocus(false);
                  }}
                />
              )
            }}
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setSearchFocus(true);
            }}
          />

          <Box
            visibility={showDeleteText ? 'visible' : 'hidden'}
            display={showDeleteText ? 'flex' : 'none'}
            alignitems="center"
            ml={isMobile ? 0 : '20px'}
            cursor={
              selectedRows.some(item => item.name.endsWith('/')) &&
              isVersioningToggled
                ? 'not-allowed'
                : 'pointer'
            }
            onClick={
              selectedRows.some(item => item.name.endsWith('/')) &&
              isVersioningToggled
                ? null
                : onDeleteClick()
            }
          >
            <Icon
              pathFill={
                selectedRows.some(item => item.name.endsWith('/')) &&
                isVersioningToggled
                  ? colors.gray
                  : null
              }
              icon="deleteFilled"
            ></Icon>
            <Text
              fontSize="14px"
              fontWeight="500"
              color={
                selectedRows.some(item => item.name.endsWith('/')) &&
                isVersioningToggled
                  ? colors.gray
                  : colors.red
              }
              ml="5px"
            >
              {t('deleteSelected')}
            </Text>
          </Box>
        </ContentWrapper>

        <Box
          display="flex"
          alignitems="center"
          justifycontent="flex-end"
          gap="10px"
          mt="10px"
        >
          <ShowVersionsText isMobile={isMobile}>
            {t('showVersions')}
          </ShowVersionsText>
          <ButtonToggle
            type="button"
            isToggled={isVersioningToggled}
            handle={handleIsLockingToggled}
          />
        </Box>
      </SerachWrapper>
    );
  }

  return (
    <>
      {canReadObjectsPerm && (
        <Box p="0 30px">
          <Box bgcolor={colors.white} p="30px">
            <BreadcrumbsWrapper>
              <Box display="flex" alignitems="center" flex="1">
                {/*<Text fontSize="14px" color={theme.themes?.colorPrimary} fontWeight="400">Buckets</Text>*/}

                <Breadcrumbs
                  aria-label="breadcrumb"
                  sx={{
                    '.MuiBreadcrumbs-separator': {
                      display: 'none'
                    },

                    '.MuiBreadcrumbs-ol': {
                      minWidth: '210px',
                      '.MuiBreadcrumbs-li': {
                        svg: {
                          cursor: 'initial'
                        },
                        '&:last-child': {
                          svg: {
                            display: 'none'
                          }
                        }
                      }
                    }
                  }}
                >
                  {breadCrumbsNames.map((value, index) => {
                    const to = `/${pathNames
                      .slice(0, index === 0 ? index + 1 : index + 2)
                      .join('/')}`;

                    return (
                      <Box
                        // eslint-disable-next-line react/no-array-index-key
                        key={`link-${index}`}
                        display="flex"
                        alignitems="center"
                        onClick={() => {
                          setDisplayLoader(true);
                          setSelectedRows([]);
                          setShowDeleteText(false);
                          setSearchValue('');
                          setSearchFocus(false);
                        }}
                      >
                        <Link to={to}>
                          <Text
                            color={
                              pathNames.length - 2 === index
                                ? theme.themes?.colorPrimary
                                : colors.gray20
                            }
                            fontSize="14px"
                            texttransform={index === 0 ? 'capitalize' : ''}
                            fontWeight="500"
                            wordbreak="break-all"
                          >
                            {value === 'buckets'
                              ? t('buckets')
                              : decodeURIComponent(value)}
                          </Text>
                        </Link>
                        <Icon
                          mr="5px"
                          ml="5px"
                          icon="arrow_right"
                          width="7px"
                          height="15px"
                          fill="#0B293A"
                        ></Icon>
                      </Box>
                    );
                  })}
                </Breadcrumbs>
              </Box>

              {(response &&
                !viewingAllVersions &&
                response.Content &&
                !displayLoader &&
                debouncedSearchTerm?.length === 0 &&
                searchValue.length === 0) ||
              isSearchingTable ||
              (debouncedSearchTerm?.length > 0 && searchValue.length > 0) ? (
                <ButtonWrapper isMobile={isMobile}>
                  {canCreateFilePerm && (
                    <Button
                      primary
                      minHeight="40px"
                      onClick={showUploadFilesModal}
                      m={isMobile ? 0 : '0 30px'}
                      isMobile={isMobile}
                    >
                      {t('uploadFiles')}
                    </Button>
                  )}
                  {canCreateFolderPerm && (
                    <Button
                      primary
                      minHeight="40px"
                      onClick={showCreateFolderModal}
                      isMobile={isMobile}
                    >
                      {t('createFolder')}
                    </Button>
                  )}
                </ButtonWrapper>
              ) : (
                <span></span>
              )}
            </BreadcrumbsWrapper>

            <BorderBottom />

            <Box relative="true">
              {response && !viewingAllVersions ? (
                (response.Content &&
                  !displayLoader &&
                  debouncedSearchTerm?.length === 0 &&
                  searchValue.length === 0) ||
                isSearchingTable ||
                (debouncedSearchTerm?.length > 0 && searchValue.length > 0) ? (
                  <div style={{ width: '100%' }}>
                    <Table
                      localeText={localizedTextsMap}
                      autoHeight
                      rows={displayLoader ? [] : response?.Content ?? []}
                      onRowClick={handleClickEvent}
                      columns={allColumns(
                        isVersioningToggled
                          ? [...columns, versionColumn]
                          : [...columns],
                        displayLoader ? [] : response?.Content ?? []
                      )}
                      pageSize={pageSize}
                      page={currentPage}
                      rowsPerPageOptions={[20, 50, 100]}
                      DataFormatString="{} {0:dd, MMM, yyyy}"
                      getRowId={row => row.id}
                      paginationMode="server"
                      rowCount={totalRows}
                      hideFooter={!response?.Content}
                      checkboxSelection={
                        canDeleteFolderPerm || canDeleteFilePerm
                      }
                      onPageChange={data => {
                        fetchNextPage(data + 1);
                      }}
                      loading={displayLoader && !viewingAllVersions}
                      components={{
                        Pagination: CustomPagination.bind(response?.Content),
                        Toolbar: QuickSearchToolbar,
                        LoadingOverlay: () => (
                          <Box heightsize="130px" p="40px 20px">
                            <LoaderWrap zIndex={3} display={true} />
                          </Box>
                        ),
                        NoResultsOverlay: () =>
                          !isSearchingTable || response?.Content === null ? (
                            <Text
                              fontSize="14px"
                              mt="10px"
                              color={colors.bodyColor}
                            >
                              {t('noData')}
                            </Text>
                          ) : null
                      }}
                      onSelectionModelChange={handleSelectCheckboxChange}
                      selectionModel={selectedRows.map(item => item?.id)}
                      disableSelectionOnClick
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={newModel =>
                        setColumnVisibilityModel(newModel)
                      }
                    />
                  </div>
                ) : (
                  <>
                    {!searchFocus && !displayLoader && (
                      <Box
                        mb="20px"
                        display="flex"
                        alignitems="center"
                        justifycontent={'flex-end'}
                      >
                        <Box
                          display="flex"
                          alignitems="center"
                          justifycontent="flex-end"
                          gap="10px"
                          mt="10px"
                        >
                          <ShowVersionsText>
                            {t('showVersions')}
                          </ShowVersionsText>
                          <ButtonToggle
                            type="button"
                            isToggled={isVersioningToggled}
                            handle={handleIsLockingToggled}
                          />
                        </Box>
                      </Box>
                    )}
                    {!response?.Content &&
                      !isSearchingTable &&
                      !searchFocus &&
                      !displayLoader && (
                        <EmptyObjectsView
                          incorrectFileName={() =>
                            showUploadToast(
                              {
                                message: t('objectNamePattern'),
                                success: false
                              },
                              'error'
                            )
                          }
                          onCreateFolderClick={showCreateFolderModal}
                          onUploadFileClick={showUploadFilesModal}
                          uploadInProgress={uploadInProgress}
                          breadCrumbsLength={breadCrumbsNames?.length}
                        />
                      )}
                  </>
                )
              ) : (
                <Box p="40px 0">
                  <LoaderWrap
                    zIndex={6}
                    display={response === undefined || viewingAllVersions}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <CreateFolderModal
            displayLoader={displayLoader}
            open={showCreateFolder}
            close={() => setShowCreateFolder(false)}
            onSubmit={e => handleCreateFolder(e)}
          />

          <UploadFilesModal
            uploadInProgress={uploadInProgress}
            setUploadInProgress={setUploadInProgress}
            open={showUploadFiles}
            close={closeUploadFilesModal}
            uploadComplete={e => uploadComplete(e)}
            preDeclaredFiles={preDeclaredFiles}
          />

          <DeleteModal
            open={showDeleteObjects}
            close={() => setShowDeleteObjects(false)}
            onSubmit={e => handleDeleteObjects(e)}
            name={t('objects')}
            deleteText={t('deleteConfirmation')}
            text={t('deleteUpperCase')}
            isLoading={buttonLoader}
          />
          <Modal
            open={showObjectDetails}
            onClose={() => setShowObjectDetails(false)}
            className="details__modal"
          >
            <BucketObjectDetails
              setLoading={setViewingAllVersions}
              open={true}
              close={() => setShowObjectDetails(false)}
              objectName={objectName}
              selectedRowVersionId={versionId}
              onDeleteConfirm={() => {
                deletePerformed();
                if (debouncedSearchTerm?.length > 0) setIsSearchingTable(true);
              }}
              onRenameFile={() => updateNamePerformed()}
              setSearchValue={setSearchValue}
              setIsSearchingTable={setIsSearchingTable}
              isSearchingTable={isSearchingTable}
              searchValue={searchValue}
              setIsVersioningToggled={setIsVersioningToggled}
              isVersioningToggled={isVersioningToggled}
              fetchBucketObjects={fetchBucketObjects}
              setToggleRefetch={setToggleRefetch}
            />
          </Modal>
        </Box>
      )}
    </>
  );
}

BucketObjects.childrenRoutes = [
  { Component: BucketObjects, index: true },
  {
    Component: BucketObjects,
    path: ':directory',
    childrenRoutes: BucketObjects.childrenRoutes
  }
];

const BorderBottom = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin: 20px 0;
`;

const Search = styled(GridToolbarQuickFilter)`
  background-color: ${colors.softGray};
  border-radius: 4px;
  &.MuiTextField-root {
    padding: 4px 10px;
    margin-left: 0;
    max-width: ${props => (props.isMobile ? '175px' : '235px')};

    input::placeholder {
      color: ${colors.gray20};
      opacity: 1;
      font-size: ${props => (props.isMobile ? '12px' : '14px')};
    }
  }
`;
const ShowVersionsText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #0b2e44;
  margin-top: 2px;
  white-space: nowrap;
  margin-left: ${props => (props.isMobile ? '5px' : 0)};
`;
const BreadcrumbsWrapper = styled.div`
  display: flex;
  justify-content: space-content;
  align-items: center;
  flex-wrap: wrap;
`;

const SerachWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-top: ${props => (props.isMobile ? '5px' : 0)};
`;
