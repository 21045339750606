import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Outlet, useParams } from 'react-router';

import { mobile } from '../../../constants/breakpoints';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import { colors } from '../../constant';
import Icon from '../../common/icon/Icon';

import BucketTabs from './BucketTabs';
import { BucketObjects } from './BucketObjects';
import BucketSettings from './BucketSettings';
import BucketUsage from './BucketUsage';

const BucketDetails = () => {
  const { name } = useParams();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  let navigate = useNavigate();

  const navigateToBucket = () => {
    navigate('/buckets');
  };

  return (
    <Box p="20px 0 0 0">
      <Box p="0 30px" display="flex" alignitems="center">
        <Icon
          onClick={navigateToBucket}
          mr="10px"
          icon="arrow_left"
          width="7px"
          height="15px"
          fill="#0B293A"
        ></Icon>
        <Text
          onClick={navigateToBucket}
          cursor="pointer"
          fontSize="18px"
          color={colors.black}
          fontWeight="500"
          isMobile={isMobile}
        >
          {name}
        </Text>
        {/*<Icon icon="globe"></Icon>*/}
        {/*<Text fontSize="14px" color={colors.bodyColor} fontWeight="400">us-east-1</Text>*/}
      </Box>

      <BucketTabs />

      <Outlet />
    </Box>
  );
};

BucketDetails.childrenRoutes = [
  { Component: BucketObjects, index: true },
  {
    Component: BucketObjects,
    path: 'objects/*',
    childrenRoutes: BucketObjects.childrenRoutes
  },
  { Component: BucketSettings, path: 'properties' },
  { Component: BucketUsage, path: 'usage' }
];

export default BucketDetails;
