/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Line } from 'rc-progress';
import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { useMediaQuery } from 'react-responsive';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import { colors } from '../../constant';
import { showUploadToast } from '../../../hooks/show-toast';
import { notificationConfig } from '../../common/notification/helpers';
import { completeUploadLinks, getUploadLinks } from '../endpoints';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import Icon from '../../common/icon/Icon';
import Input from '../../common/input/Input';
import Title from '../../common/title/Title';
import Button from '../../common/button/Button';
import CloseModalIcon from '../../../assets/icons/x.svg';
import NewNotification from '../../common/notification/NewNotification';
import { mobile, nonMobile, xsmobile } from '../../../constants/breakpoints';

const FILE_CHUNK_SIZE = 100_000_000;

export function UploadFilesModal({
  open,
  close,
  preDeclaredFiles,
  uploadComplete
}) {
  const { theme } = useStoreState(actions => actions.theme);
  const {
    filesUploadingGlobal,
    selectedFilesGlobal,
    customToastId
  } = useStoreState(state => state.fileUpload);
  const {
    addFileToUploadingFiles,
    removeFileByUploadingModal,
    calculatePercentage,
    removeFileFromSelectedFiles,
    setSelectedFilesToArray,
    setCustomToastId,
    setFileSuccessful,
    setFileError
  } = useStoreActions(actions => actions.fileUpload);
  const [startUpload, setStartUpload] = useState([]);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  const isXsMobile = useMediaQuery({ query: `(max-width: ${xsmobile}px)` });
  const nonMobileSize = useMediaQuery({ query: `(min-width: ${nonMobile}px)` });

  const randomToastId = () => {
    setCustomToastId(`${Math.random()}`);
  };

  const incorrectFileName = () => {
    toast.dismiss();
    showUploadToast(
      { message: t('objectNamePattern'), success: false },
      'error'
    );
  };

  const { name, '*': directory } = useParams();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: true,
    disabled: filesUploadingGlobal?.length > 0,
    onDrop: acceptedFiles => {
      browsFileClicked();
      acceptedFiles.map((file, index) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const files = [];
      let filePaths = [];
      const currentFilesNames = selectedFilesGlobal.map(f => f.file.path);
      acceptedFiles.forEach(acceptedFile => {
        const fileName = acceptedFile.name;
        const filePath = acceptedFile.path.replace(fileName, '');

        if (
          ['%', '<', '>'].some(item => acceptedFile.path.indexOf(item) > -1) ||
          fileName.indexOf('%') > -1
        ) {
          incorrectFileName();
        } else {
          const existingFileIndex = currentFilesNames.indexOf(
            acceptedFile.path
          );
          if (existingFileIndex > -1) {
            selectedFilesGlobal[existingFileIndex] = {
              file: acceptedFile,
              percentage: 0,
              displaySuccessIcon: false,
              displayErrorIcon: false
            };
          } else {
            files.push({
              file: acceptedFile,
              percentage: 0,
              displaySuccessIcon: false,
              displayErrorIcon: false
            });

            let previousPath = '/';
            filePath
              .split('/')
              .filter(e => e !== '')
              .forEach(function(item) {
                const path = previousPath + item + '/';
                filePaths.push(path);
                previousPath = path;
              });
          }
        }
      });

      filePaths = [...new Set(filePaths)];
      filePaths.forEach(function(path) {
        files.push({
          file: new File([''], path, {}),
          percentage: 0,
          displaySuccessIcon: false,
          displayErrorIcon: false
        });
      });
      files.push(...selectedFilesGlobal);
      setSelectedFilesToArray(files);
    }
  });

  useEffect(() => {
    if (filesUploadingGlobal?.length === 0 && startUpload && open) {
      if (selectedFilesGlobal.filter(f => !f.displayErrorIcon).length === 0) {
        setStartUpload(false);
        setTimeout(func => {
          uploadComplete();
        }, 500);
      }
    }
  }, [filesUploadingGlobal]);

  const uploadProgress = file => progress => {
    let percentage = Math.floor((progress.loaded * 100) / progress.total);
    if (percentage > 90) {
      percentage = 90;
    }
    calculatePercentage({
      fileName: file,
      number: percentage,
      updateAnyway: false
    });
  };

  useEffect(() => {
    if (!open && filesUploadingGlobal?.length === 0) {
      setSelectedFilesToArray([]);
    } else {
      if (
        open &&
        preDeclaredFiles?.length > 0 &&
        filesUploadingGlobal?.length === 0
      ) {
        setSelectedFilesToArray(preDeclaredFiles);
        setStartUpload(false);
      }
      if (filesUploadingGlobal?.length > 0) {
        setStartUpload(true);
      } else {
        setStartUpload(false);
      }
    }
  }, [open]);

  async function uploadParts(file, urls, chunkModulus) {
    const contentTypeValue = file.type.toString();
    const cancelTokenSource = axios.CancelToken.source();
    const config = {
      cancelToken: cancelTokenSource.token,
      ...(urls?.length === 1
        ? { onUploadProgress: uploadProgress(file.path) }
        : {})
    };

    const instance = axios.create(config);
    delete instance.defaults.headers.put['Content-Type'];

    let successfulUploadCount = 0;
    const promises = [];

    for (const [i, key] of Object.entries(urls)) {
      const index = parseInt(i);
      const start = index * FILE_CHUNK_SIZE;
      let end = (index + 1) * FILE_CHUNK_SIZE;
      if (index === urls.length - 1) {
        end = end + chunkModulus;
      }

      const blob =
        index < urls.length - 1 ? file.slice(start, end) : file.slice(start);
      const partHeaders = {
        'Content-Type': contentTypeValue
      };
      if (urls.length > 1) {
        // Push a single promise for multiple URLs
        promises.push(
          instance
            .put(key.Url, blob, {
              headers: partHeaders,
              cancelToken: cancelTokenSource.token
            })
            // eslint-disable-next-line no-loop-func
            .then(res => {
              successfulUploadCount++; // Increment successful upload count when upload is successful
              const percentage = (successfulUploadCount / urls.length) * 100;
              calculatePercentage({
                fileName: file?.name,
                number: percentage,
                updateAnyway: true
              });
              return res;
            })
            .catch(error => {
              // Handle upload error
              console.error(error);
            })
        );
      } else {
        // Push a single promise for a single URL
        promises.push(
          instance.put(key.Url, blob, {
            headers: partHeaders,
            cancelToken: cancelTokenSource.token
          })
        );
      }
    }

    try {
      const resParts = await Promise.all(promises);
      toast.update(customToastId, { autoClose: 5000 });
      return {
        success: resParts.every(part => part.status === 200),
        data: resParts.map((part, index) => ({
          Url: part.headers.etag.replaceAll('"', ''),
          Position: index + 1
        }))
      };
    } catch (e) {
      cancelTokenSource.cancel('Request failed!');
      return {
        success: false,
        message: e.message,
        data: []
      };
    }
  }

  async function upload() {
    toast.dismiss();
    randomToastId();
    setStartUpload(true);
    const files = selectedFilesGlobal.filter(f => f.percentage === 0);
    const batchSize = 5;
    const numBatches = Math.ceil(files.length / batchSize);

    for (let i = 0; i < numBatches; i++) {
      const batch = files.slice(i * batchSize, (i + 1) * batchSize);
      const promises = batch.map(
        (file, index) =>
          // eslint-disable-next-line no-async-promise-executor
          new Promise(async (resolve, reject) => {
            addFileToUploadingFiles(file.file.path);
            try {
              await uploadFile(file);
              resolve(index);
            } catch (error) {
              reject(error);
            }
          })
      );

      await Promise.all(promises);

      if (i < numBatches - 1) {
        await new Promise(resolve => setTimeout(resolve, 500)); // delay of x seconds
      }
    }
  }

  const uploadFile = file => {
    const fileName = file.file.name;
    const fileMetaData = file.file.type;
    const filePath = file.file.path ? file.file.path.replace(fileName, '') : '';

    const dirName = directory?.length > 0 ? directory.concat('/') : '';
    setFileSuccessful({ file: file, status: false });
    setFileError({ file: file, status: false });
    calculatePercentage({
      fileName: file.file.path,
      number: 0,
      updateAnyway: true
    });

    let fileParts = Math.ceil(file.file.size / FILE_CHUNK_SIZE); // Chunks 100MB
    let chunkModulus = file.file.size % FILE_CHUNK_SIZE;
    if (chunkModulus < 5_000_000) {
      fileParts = fileParts - 1;
    } else {
      chunkModulus = 0;
    }

    getUploadLinks(
      name,
      dirName.concat(filePath),
      fileName,
      fileParts,
      fileMetaData
    ).then(([res]) => {
      if (res?.data && res?.data?.Parts && res?.data?.Parts?.length > 0) {
        const uploadID = res?.data.UploadId;
        const fileType = res?.data.name;
        uploadParts(file.file, res?.data?.Parts, chunkModulus).then(
          uploaded => {
            if (uploaded?.success) {
              if (fileParts > 1) {
                completeUploadLinks(
                  name,
                  dirName.concat(filePath).concat(fileName),
                  uploaded?.data,
                  uploadID,
                  fileType,
                  fileMetaData
                ).then(([result]) => {
                  if (result?.success) {
                    calculatePercentage({
                      fileName: file.file.path,
                      number: 100,
                      updateAnyway: true
                    });
                    setFileSuccessful({ file: file, status: true });
                    removeFile(file.file);
                    removeFileByUploadingModal(file.file.path);
                    toast.success(
                      <NewNotification
                        onClick={() => randomToastId()}
                        notification={{
                          display: true,
                          success: true,
                          message: result.message
                        }}
                        type={'success'}
                      />,
                      {
                        ...notificationConfig('success'),
                        toastId: customToastId
                      }
                    );
                  } else {
                    setFileError({ file: file, status: true });
                    removeFileByUploadingModal(file.file.path);
                    toast.error(
                      <NewNotification
                        onClick={() => randomToastId()}
                        notification={{
                          display: true,
                          success: true,
                          message: result.message
                        }}
                        type={'error'}
                      />,
                      {
                        ...notificationConfig('error'),
                        toastId: `${customToastId}-error`
                      }
                    );
                  }
                });
              } else {
                if (res?.success) {
                  calculatePercentage({
                    fileName: file.file.path,
                    number: 100,
                    updateAnyway: true
                  });
                  setFileSuccessful({ file: file, status: true });
                  removeFile(file.file);
                  removeFileByUploadingModal(file.file.path);
                  toast.success(
                    <NewNotification
                      onClick={() => randomToastId()}
                      notification={{
                        display: true,
                        success: true,
                        message:
                          res?.code ||
                          res?.Code.replace(
                            t('objectsHaveBeen'),
                            t('objectHasBeen')
                          )
                      }}
                      type={'success'}
                    />,
                    {
                      ...notificationConfig('success'),
                      toastId: customToastId
                    }
                  );
                } else {
                  setFileError({ file: file, status: true });
                  removeFileByUploadingModal(file.file.path);
                  toast.error(
                    <NewNotification
                      onClick={() => randomToastId()}
                      notification={{
                        display: true,
                        success: true,
                        message: res?.code || res?.Code
                      }}
                      type={'error'}
                    />,
                    {
                      ...notificationConfig('error'),
                      toastId: `${customToastId}-error`
                    }
                  );
                }
              }
            } else {
              setFileError({ file: file, status: true });
              removeFileByUploadingModal(file.file.path);
              toast.error(
                <NewNotification
                  onClick={() => randomToastId()}
                  notification={{
                    display: true,
                    success: true,
                    message: t('ErrCcc0021'),
                    customMessage: true
                  }}
                  type={'error'}
                />,
                {
                  ...notificationConfig('error'),
                  toastId: `${customToastId}-error`
                }
              );
            }
          }
        );
      } else {
        setFileError({ file: file, status: true });
        removeFileByUploadingModal(file.file.path);
        toast.error(
          <NewNotification
            onClick={() => randomToastId()}
            notification={{
              display: true,
              success: true,
              message: res?.code || res?.Code
            }}
            type={'error'}
          />,
          { ...notificationConfig('error'), toastId: `${customToastId}-error` }
        );
      }
    });
  };

  const removeFile = file => {
    removeFileFromSelectedFiles(file?.path);
  };

  const clearFiles = () => {
    setSelectedFilesToArray([]);
    setStartUpload(false);
  };

  const onLinkClick = file => {
    toast.dismiss();
    randomToastId();
    setStartUpload(true);
    addFileToUploadingFiles(file.file.path);
    uploadFile(file);
  };

  const browsFileClicked = () => {
    setStartUpload(false);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return t('zeroBytes');

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [t('bytes'), 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <ModalBasic
      style={{ width: '600px', overflowy: 'auto' }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title fontSize="16px" fontWeight="500" color={colors.black}>
            {t('uploadFiles')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <Box m="20px auto">
            {!startUpload && (
              <Box display="flex">
                <Box
                  {...getRootProps({
                    className: `dropzone ${isDragActive ? 'dragActive' : ''}`
                  })}
                >
                  <input {...getInputProps()} />
                  <Box display="flex" alignitems="center">
                    <Icon icon="upload" fill={colors.black} mr="10px" />
                    <Text fontSize="14px" color={colors.gray30}>
                      {t('dragDropFiles')}
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}
            <aside>
              <ul className="files-upload-wrap">
                {selectedFilesGlobal &&
                  selectedFilesGlobal.map((file, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>
                        <Box border="true" p="10px" m="0 5px 10px 0">
                          <Box
                            display="flex"
                            alignitems="center"
                            justifycontent="space-between"
                            relative="true"
                          >
                            <Box
                              display="flex"
                              alignitems="flex-start"
                              relative="true"
                            >
                              <Icon
                                icon="file"
                                minwidth="16px"
                                width="16px"
                                height="22px"
                              />
                              <Icon
                                icon="success"
                                width="10px"
                                height="10px"
                                position="absolute"
                                top="2px"
                                left="-4px"
                                display={
                                  file.displaySuccessIcon ? 'block' : 'none'
                                }
                              />
                              <Icon
                                icon="error"
                                width="10px"
                                height="10px"
                                position="absolute"
                                top="2px"
                                left="-4px"
                                display={
                                  file.displayErrorIcon ? 'block' : 'none'
                                }
                              />
                              <TextFile>
                                <Text wordbreak="break-word">
                                  {file.file.name}
                                </Text>
                                <Text
                                  color={colors.gray10}
                                  fontSize="10px"
                                  fontWeight="500"
                                >
                                  {formatBytes(file.file.size)}
                                </Text>
                              </TextFile>
                            </Box>
                            <Box
                              display={
                                file.displayErrorIcon || file.displaySuccessIcon
                                  ? 'flex'
                                  : 'none'
                              }
                            >
                              {file.displayErrorIcon && (
                                <>
                                  <Text
                                    display={'flex'}
                                    fontSize="14px"
                                    center="true"
                                    minwidth="60px"
                                    ml="8px"
                                  >
                                    <Link
                                      display={'flex'}
                                      onClick={() => onLinkClick(file)}
                                    >
                                      {t('tryAgain')}
                                    </Link>
                                  </Text>
                                  <Icon
                                    ml="10px"
                                    icon="x"
                                    width="15px"
                                    display={'block'}
                                    onClick={removeFile.bind(this, file.file)}
                                  />
                                </>
                              )}
                            </Box>
                          </Box>

                          <Box
                            display={
                              !file.displayErrorIcon && !file.displaySuccessIcon
                                ? 'flex'
                                : 'none'
                            }
                            alignitems="center"
                          >
                            <Line
                              className="line-progress-bar"
                              percent={file.percentage}
                              strokeWidth={1.5}
                              strokeColor={theme.themes.colorPrimary}
                            />
                            <Icon
                              ml="10px"
                              icon="x"
                              width="15px"
                              display={startUpload ? 'none' : 'block'}
                              onClick={removeFile.bind(this, file.file)}
                            />
                          </Box>
                        </Box>
                      </li>
                    );
                  })}
              </ul>
            </aside>
          </Box>
        </ModalBody>

        <ModalFooter
          display="flex"
          alignitems="center"
          justifycontent={
            !startUpload && selectedFilesGlobal?.length > 0
              ? 'space-between'
              : 'center'
          }
          isXsMobile={isXsMobile}
          isMobile={isMobile}
        >
          <Button
            cancel
            color={colors.gray10}
            onClick={clearFiles}
            mr="10px"
            className="cancel no-pad"
            display={
              !startUpload && selectedFilesGlobal?.length > 0
                ? 'initial'
                : 'none'
            }
          >
            {t('clearFiles')}
          </Button>
          <Box display="flex" alignitems="center">
            {!startUpload && (
              <Box {...getRootProps()}>
                <BrowseFile
                  isXsMobile={isXsMobile}
                  isMobile={isMobile}
                  nonMobileSize={nonMobileSize}
                  padding={isMobile ? '5px 10px' : '10px 30px'}
                  onClick={browsFileClicked}
                >
                  {t('browseFiles')}
                </BrowseFile>
              </Box>
            )}

            <Button
              height="40px"
              minwidth="120px"
              fontWeight="500"
              center={isMobile ? 'true' : 'false'}
              primary
              onClick={
                !startUpload && selectedFilesGlobal?.length > 0 ? upload : null
              }
              isMobile={isMobile}
              ml="10px"
              display={
                !startUpload && selectedFilesGlobal?.length > 0
                  ? 'initial'
                  : 'none'
              }
            >
              {t('startUpload')}
            </Button>
          </Box>
        </ModalFooter>
      </div>
    </ModalBasic>
  );
}

const Link = styled.a`
  color: ${({ theme }) =>
    theme?.themes?.colorPrimary ?? colors.primary} !important;
  cursor: pointer;
  text-decoration: underline !important;

  &:hover {
    text-decoration: underline !important;
    color: ${({ theme }) =>
      theme?.themes?.colorPrimary ?? colors.primary} !important;
  }
`;

const BrowseFile = styled.button`
  padding: ${props => props.padding};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  background-color: ${({ theme }) => theme.themes?.colorPrimary};
  border-color: transparent;
  transition: 0.3s box-shadow ease-in-out;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: ${props => (props.isMobile ? '40px' : '20px')};
  min-height: ${props => (props.nonMobileSize ? '40px' : '')};
  min-width: ${props => props.isMobile && '120px'};

  &:hover {
    box-shadow: 0 12px 16px -10px ${({ theme }) => theme.themes?.colorPrimary};
  }
`;

const TextFile = styled.span`
  font-size: 12px;
  color: ${colors.bodyColor};
  font-weight: 500;
  margin-left: 10px;
`;

UploadFilesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  preDeclaredFiles: PropTypes.array.isRequired,
  uploadComplete: PropTypes.func.isRequired
};
