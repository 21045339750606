/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { wait } from '@testing-library/user-event/dist/utils';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Box } from '@mui/material';
import styled from 'styled-components';

import { mobile } from '../../../constants/breakpoints';
import { showUploadToast } from '../../../hooks/show-toast';

import { colors } from '../../constant';
import { LanguageSelector } from '../../common/language-selector';

import { ResponsiveWrapper } from './styles';
import LoginHeader from './components/Header';
import LoginForm from './components/LoginForm';

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useStoreState(state => state.user);
  const { theme } = useStoreState(state => state.theme);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  useEffect(() => {
    if (userData && userData.Id > 0) {
      navigate('/buckets');
    }
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem('ssoDeletedOrg') &&
      !localStorage.getItem('userIsSso') &&
      (localStorage.getItem('accountDeActive') ||
        localStorage.getItem('invalidToken') ||
        localStorage.getItem('refreshTokenExpired'))
    ) {
      toast.dismiss();
      showUploadToast(
        {
          message: localStorage.getItem('invalidToken')
            ? t('unauthorized')
            : localStorage.getItem('accountDeActive')
            ? t('youHaveBeenLoggedOut')
            : t('youHaveBeenLoggedOutDueToInactivity'),
          success: false
        },
        'error'
      );
      wait(3000).then(() => {
        localStorage.removeItem('accountDeActive');
        localStorage.removeItem('invalidToken');
        localStorage.removeItem('inactivity');
        localStorage.removeItem('refreshTokenExpired');
      });
    }
  }, []);

  console.log('THIS IS QA ENV');

  return (
    <LoginFormWrapper isMobile={isMobile}>
      <Helmet>
        <meta name="description" content={theme?.loginTitle} />
      </Helmet>
      <ResponsiveWrapper
        boxsizing="initial"
        bgcolor={colors.white}
        borderradius="4px"
        maxwidth="380px"
        minwidth="380px"
        m="0 auto"
        p="30px"
        maxheightsize="-webkit-fill-available"
        isMobile={isMobile}
      >
        <LoginHeader theme={theme} />
        <LoginForm />
        <Box
          sx={{
            marginBlockStart: '20px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LanguageSelector />
        </Box>
      </ResponsiveWrapper>
    </LoginFormWrapper>
  );
}

const LoginFormWrapper = styled(Box)`
  position: ${({ isMobile }) => (isMobile ? 'absolute' : 'static')};
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '100%' : '100vh')};
  margin-top: ${({ isMobile }) => (isMobile ? 0 : '-60px')};
  display: flex;
  align-items: center;
`;
